.intersection-wrapper .close-arrow-col {
    margin-top: 0.5em;
}

.intersection-url-row {
    display: flex;
    align-items: center;
}

.close-arrow-col {
    display: flex;
}

.more-info-col {
    margin-bottom: -0.5em;
    width: auto;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
}

.wavy-arrow {
    padding-top: 1.5em;
}

.more-info-wrapper {
    margin-left: 2em;
    padding: 1em;
    display: flex;
    align-items: center;
}

.more-info-wrapper:hover {
    color: #fff;
    background-color: #000;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    cursor: pointer;
}

.more-info-wrapper-in-process:hover {
    background-color: #fff;
    color: #000;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    cursor: pointer;
}

.more-info {
    font-size: 16px;
    word-break: break-all;
}

.making-waves-url-wrapper {
    margin-left: 2em;
    padding: 1em;
    display: flex;
    align-items: center;
}

.first-inter-text {
    padding-top: 2em;
}




/********************   FIRST INTERSECTION   ********************/

.first-intersection {
    background-color: #000;
    color: #fff;
    padding: 10vh 0;
}

.inter-figure-text-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4em;
    padding: 5vh 0;
}

.inter-container-text-left-col {
    padding-left: 25%;
    width: 85%;
}

.inter-container-text-right-col {
    padding-right: 25%;
    width: 90%;
}

.first-intersection-figure-1 {
    padding-right: 30%;
}

.first-intersection-figure-3 {
    padding-right: 10%;
}

.thick-line-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}

.long-thick-line {
    width: 80%;
}


/*******************   SECOND INTERSECTION *********************/


.second-intersection {
    background-color: #215426;
    color: #fff;
    padding: 5% 0;
}

.second-intersection-figure-1 {
    padding-right: 30%;
}

.second-intersection-figure-2 {
    padding-left: 30%;
}

.second-intersection-figure-3 {
    padding-left: 27%;
    padding-top: 5%;
}

.second-intersection-figure-5 {
    float: right;
    padding-top: 30%;
}

.second-intersection-figure-6 {
    padding: 5% 0;
}

.second-intersection-figure-7 {
    padding-left: 15%;
}






/****************   THIRD INTERSECTION    *******************/


.third-intersection {
    background-image: url("../assets/img/intersections/map-lines.png");
    background-color: #29FF42;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5% 0;
}

.third-inter-figure-text-wrapper-1 .d-flex:first-child {
    padding-top: 20vh;
}

.third-intersection-figure-1 {
    padding-right: 50%;
}

.third-intersection-figure-2 {
    padding-left: 30%;
}

.third-intersection-figure-3 {
    max-width: 40%;
    margin-right: 5%;
}

.third-intersection-figure-5 {
    padding-left: 40%;
    padding-right: 10%;
}





/***************    FOURTH INTERSECTION    *****************/

.fourth-intersection {
    padding-bottom: 6vh;
}

.fourth-section-hero-wrapper {
    max-width: 80%;
    padding-top: 8%;
    padding-left: 6%;
}

.fourth-section-hero-heading {
    font-size: 60px;
    font-weight: 900;
}

.fourth-section-hero-link-wrapper {
    margin: 8em 0 8em 5em;
}

.fourth-section-hero-link {
    border: 2px solid #000;
    border-radius: 50%;
    padding: 15px 40px;
}

.fourth-section-hero-link:hover {
    border: 2px solid #29FF42;
    color: #29FF42;
}

.undsg-span {
    border-bottom: 2px solid #29FF42;
    color: #29FF42;
}

.fourth-intersection-img-caption {
    margin: 0 auto;
    font-weight: 700;
    max-width: 58%;
    font-size: 26px;
    padding: 16vh 0 12vh 0;
}

.wider-context {
    font-weight: 700;
    margin-bottom: 1.4em;
}

.fourth-intersection-here-link {
    font-weight: 700;
}

.fourth-intersection-here-link:hover {
    font-weight: 700;
    color: #29FF42;
    border-bottom: 2px solid #29FF42;

}

/***************    FIFTH INTERSECTION    *****************/

.fifth-intersection {
    padding: 5vh 0 10vh 0;
    background-image: url("../assets/img/intersections/map-lines-white.png");
    background-color: #E2E2E2;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fifth-intercontainer {
    width: 60%;
    padding-top: 10vh;
}

.fifth-intertext-wrapper {
    width: 60%;
}

.fifth-intertext-wrapper h2 {
    margin: 3vh 0;
}